import React from 'react';
import './terms-of-use-section-container.scss';

export interface TermsOfUseSectionProps {
	header: React.ReactNode;
	terms: string[];
}

export interface TermsOfUseSectionImageProps {
	imageUrl: string;
	style: React.CSSProperties;
}

export interface TermsOfUseSectionContainerProps {
	list: TermsOfUseSectionProps[];
	decorations?: TermsOfUseSectionImageProps[];
}

const TermsOfUseSectionContainer: React.FC<TermsOfUseSectionContainerProps> = ({
	list,
	decorations,
}: TermsOfUseSectionContainerProps) => {
	return (
		<li className="terms-of-use-container">
			{list.map((item: TermsOfUseSectionProps, index: number) => (
				<section key={index}>
					<h3 className="atmospheric-text">{item.header}</h3>
					<ol>
						{item.terms.map((term: string, termIndex: number) => (
							<li
								dangerouslySetInnerHTML={{ __html: term }}
								key={`${index}_${termIndex}`}
							/>
						))}
					</ol>
				</section>
			))}
			{decorations?.map(
				(item: TermsOfUseSectionImageProps, index: number) => (
					<img className="terms-of-use-container__deco" src={item.imageUrl} style={item.style} key={index} />
				)
			)}
		</li>
	);
};

export { TermsOfUseSectionContainer };
