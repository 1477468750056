import {
	TermsOfUseSectionContainer,
	TermsOfUseSectionContainerProps,
} from '@/components/terms-of-use-section-container';
import { configs } from '@/constants/configs';
import { TERMS_OF_USE } from '@/constants/terms-of-use';
import { DefaultLayout } from '@/layouts/default-layout/DefaultLayout';
import '../styles/global.scss';
import '../styles/terms-of-use-page.scss';

const pageName: string = 'Terms of use';

export default function TermsOfUse() {
	return (
		<DefaultLayout className="terms-of-use-page" pageTitle={`${configs.defaultPageTitle} - ${pageName}`}>
      <div className="terms-of-use-page__header-wrapper" data-content={pageName}>
        <h2 className="terms-of-use-page__header atmospheric-text">
          {pageName}
        </h2>
      </div>
			<ol className="terms-of-use-page__section-list corbel-text">
				{TERMS_OF_USE.map(
					(term: TermsOfUseSectionContainerProps, index: number) => (
						<TermsOfUseSectionContainer {...term} key={index} />
					)
				)}
			</ol>
		</DefaultLayout>
	);
}
